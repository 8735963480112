import { Timer } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import { SwCategoriesFilterBar } from '@lib/components/SwCategoriesFilterBar';
import SwEmptySection from '@lib/components/SwEmptySection';
import { SwFeaturedSection } from '@lib/components/SwFeaturedSection';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { black, grey3, grey5 } from '@lib/components/bedrock/SwColors';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwSpacer from '@lib/components/bedrock/layout/SwSpacer';
import { SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import { DateCard } from '@app/events/DateCard';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import SwPagination from '../components/v1/pagination/SwPagination';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const Header = styled('div')`
    padding-block-start: 32px;
    padding-block-end: 64px;
`;

const StyledDateCardProps = styled(DateCard)`
    aspect-ratio: ${AspectRatios.square};
    height: 64px;
    background: transparent;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${grey5};
`;

const Events = ({ data, pageContext }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { title, subtitle } = data.page;

    const { currentPage, pageCount } = data.allGraphCmsEvent.pageInfo;
    const events = data.allGraphCmsEvent.nodes || [];

    const pinnedEvent = data.pinnedEvents?.nodes[0];

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.resources.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <SwContainer>
                        <Header>
                            <SwGrid columns={{ xs: ['100%'], md: ['60%', 'auto'] }} gap={32}>
                                <SwTypography color={grey3} component={'h1'} variant={'h3'}>
                                    <SwTypography color={black} component={'span'} variant={'h3'}>
                                        {title}
                                    </SwTypography>{' '}
                                    {subtitle}
                                </SwTypography>
                            </SwGrid>
                        </Header>
                    </SwContainer>
                    <SwFeaturedSection
                        abstract={pinnedEvent.abstract}
                        category={pinnedEvent.category}
                        coverImage={pinnedEvent.coverImage}
                        meta={<StyledDateCardProps startDate={pinnedEvent.startDate} />}
                        slug={`/${pinnedEvent.slug}`}
                        tags={pinnedEvent.tags}
                        title={pinnedEvent.title}
                    />
                    <SwSubNav>
                        <SwCategoriesFilterBar
                            activeCategory={pageContext.category}
                            allCategoryLabel={t('all_events')}
                            allCategoryLink={WEBSITE_MENU_ITEM.events.link}
                            group={data.eventGroupByCategories.group}
                            lastRecordId={data.lastEvent.nodes[0].id}
                        />
                    </SwSubNav>
                    <SwContainer>
                        <Box display={'flex'} flexDirection={'column'} pt={4} width={'100%'}>
                            <div>
                                {events.length > 0 ? (
                                    <SwGrid columns={{ xs: 1, sm: 2, md: 3 }}>
                                        {events.map((event) => (
                                            <SwRecordCard
                                                badge={
                                                    dayjs().isAfter(new Date(event.endDate)) ? (
                                                        <SwTag color={'error'} Icon={<Timer />}>
                                                            PAST EVENT
                                                        </SwTag>
                                                    ) : null
                                                }
                                                key={event.id}
                                                meta={<DateCard startDate={event.startDate} />}
                                                metaProps={{ style: { width: 64, aspectRatio: AspectRatios.square } }}
                                                record={event}
                                                titleHeading={'h2'}
                                            />
                                        ))}
                                    </SwGrid>
                                ) : (
                                    <>
                                        <SwSpacer direction={'vertical'} spacing={12} />
                                        <SwEmptySection />
                                        <SwSpacer direction={'vertical'} spacing={16} />
                                    </>
                                )}
                                {pageCount > 1 && (
                                    <>
                                        <SwLine color={grey5} spacing={2} />
                                        <SwPagination
                                            basePath={pageContext.basePath}
                                            currentPage={currentPage ?? 1}
                                            pageCount={pageCount}
                                        />
                                    </>
                                )}
                                <SwLine color={grey5} spacing={4} />
                            </div>
                        </Box>
                    </SwContainer>

                    <SwFlexBox flexDirection={'column'} gap={theme.spacing(4)}>
                        <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={16} width={'100%'}>
                            <SwContainer>
                                <SwFlexBox alignItems={'center'} gap={4}>
                                    <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                        {t('blog')}
                                    </SwTypography>
                                </SwFlexBox>
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.latestArticles.nodes}
                                    renderItems={(article) => (
                                        <SwRecordCard
                                            baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                            key={article.id}
                                            record={article}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </SwFlexBox>
                    </SwFlexBox>

                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    pageContext,
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={pageContext.noIndex ?? seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getEventsPageInformation(
        $id: String
        $limit: Int
        $skip: Int
        $category: String
        $idToExclude: String
        $locale: GraphCMS_Locale = en
    ) {
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        eventGroupByCategories: allGraphCmsEvent(filter: { locale: { eq: $locale }, actualLocale: { eq: $locale } }) {
            totalCount
            group(field: category___label, limit: 9) {
                nodes {
                    id
                }
                fieldValue
            }
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        lastEvent: allGraphCmsEvent(
            filter: { locale: { eq: $locale }, actualLocale: { eq: $locale }, unlisted: { eq: false } }
            sort: { order: DESC, fields: startDate }
            limit: 1
        ) {
            nodes {
                id
            }
        }
        allGraphCmsEvent(
            sort: { order: DESC, fields: startDate }
            limit: $limit
            skip: $skip
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                id: { ne: $idToExclude }
                category: { label: { eq: $category } }
                unlisted: { eq: false }
            }
        ) {
            nodes {
                ...EventCard_GraphCMS_Event
                coverImage {
                    alt
                    gatsbyImageData(height: 290, layout: FIXED, placeholder: BLURRED)
                }
            }
            pageInfo {
                currentPage
                pageCount
                perPage
                totalCount
            }
        }
        pinnedEvents: allGraphCmsEvent(
            sort: { order: DESC, fields: startDate }
            limit: 1
            filter: { locale: { eq: $locale }, actualLocale: { eq: $locale }, unlisted: { eq: false } }
        ) {
            nodes {
                ...EventCard_GraphCMS_Event
                coverImage {
                    alt
                    gatsbyImageData(height: 340, layout: FIXED, placeholder: BLURRED)
                }
            }
        }
        enPage: graphCmsPage(slug: { eq: "events" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            slug
            subtitle
            title
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default Events;
