import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwLinkBox, SwLinkOverlay } from '@lib/components/bedrock/navigation/SwLinkBox';
import SwContainer from '../../components/v1/SwContainer';
import { grey3, grey5, grey6, white } from './bedrock/SwColors';
import SwTag from './bedrock/badges/SwTag';
import SwTypography from './bedrock/typography/SwTypography';

export type SwFeaturedSectionProps = {
    title: string;
    abstract: string;
    coverImage: any;
    slug?: string;
    showLabel?: boolean;
    tags?: any[];
    category?: { label: string };
    meta?: React.ReactNode;
};

const HeroContainer = styled('div')`
    --shadow-color: 0 0% 51%;
    --shadow-strength: 20%;

    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        box-shadow:
            inset 0px 11px 16px -10px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%)),
            inset 0px -11px 16px -10px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
    }
`;

const Abstract = styled(SwTypography)`
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Paper = styled('div')`
    z-index: 1;
    display: flex;
    align-items: start;
    flex-direction: column;
    height: fit-content;
    border: 1px solid ${grey5};
    border-radius: 8px;
    padding: ${({ theme }) => theme.spacing(4)};
    gap: ${({ theme }) => theme.spacing(2)};
    margin-block-start: ${({ theme }) => theme.spacing(4)};
    inline-size: 50%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        inline-size: 100%;
    }
`;

const Container = styled(SwLinkBox)`
    height: 575px;
    position: relative;
    cursor: pointer;

    & .sw-feature-section_paper {
        background: ${white};
        transition: all 0.25s ease-out;
        box-shadow:
            0px 1px 4px rgba(0, 0, 0, 0.12),
            0px 0px 32px rgba(130, 130, 130, 0.18);
    }

    & .sw-featured-section__image {
        transform: scale(1);
        transition: transform 0.25s ease-out;
    }

    &:hover,
    &:focus-within {
        & .sw-featured-section__image {
            transform: scale(1.02);
        }

        & .sw-feature-section_paper {
            background: ${grey6};
            transition: transform 0.25s ease-out;
            box-shadow:
                0px 1px 4px rgba(0, 0, 0, 0.06),
                0px 0px 12px rgba(130, 130, 130, 0.08);
        }
    }
`;

export const SwFeaturedSection: React.FC<SwFeaturedSectionProps> = (props) => {
    const { abstract, category, coverImage, slug, tags, title, meta, showLabel = true } = props;

    return (
        <Container>
            <HeroContainer>
                <GatsbyImage
                    alt={coverImage.alt}
                    className={'sw-featured-section__image'}
                    image={coverImage.gatsbyImageData}
                    style={{ height: '100%', width: '100%' }}
                />
            </HeroContainer>
            <SwContainer maxHeight={true} withMaxWidth={true}>
                <Paper className={'sw-feature-section_paper'}>
                    <SwFlexBox flexDirection={'column'} gap={16}>
                        {showLabel && category && (
                            <SwTypography
                                bold={true}
                                color={grey3}
                                component={'p'}
                                uppercase={true}
                                variant={'caption'}
                            >
                                {category?.label}
                            </SwTypography>
                        )}
                        <SwFlexBox alignItems={'center'} gap={16}>
                            {meta ? meta : null}
                            <SwTypography component={'h2'} variant={'h4'}>
                                {slug ? <SwLinkOverlay to={slug}>{title}</SwLinkOverlay> : title}
                            </SwTypography>
                        </SwFlexBox>
                    </SwFlexBox>
                    <Abstract color={grey3} variant={'body1'}>
                        {abstract}
                    </Abstract>
                    {tags?.length ? (
                        <SwFlexBox flexDirection={'row'} flexWrap={'wrap'} gap={8}>
                            {tags.map((tag) => (
                                <SwTag color={tag.color} key={tag.id} size={'small'} uppercase={true}>
                                    {tag.label}
                                </SwTag>
                            ))}
                        </SwFlexBox>
                    ) : null}
                </Paper>
            </SwContainer>
        </Container>
    );
};
