import React from 'react';
import { styled } from '@mui/material';
import { white } from '../SwColors';

export const StyledButtonGroup = styled('div')`
    display: flex;
    align-items: center;

    .MuiButton-root:not(:last-child) {
        border-right: none;
        border-radius: 0;
    }

    .MuiButton-root.primary:not(:last-child),
    .MuiButton-root.danger:not(:last-child),
    .MuiButton-root.success:not(:last-child) {
        border-right: solid 1px ${white};
    }

    .MuiButton-root:first-child {
        border-radius: ${({ theme }) => theme.spacing(1)} 0 0 ${({ theme }) => theme.spacing(1)};
    }

    .MuiButton-root:last-child {
        border-radius: 0 ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)} 0;
    }
`;

const SwButtonGroup: React.FunctionComponent<SwButtonGroupProps> = (props: SwButtonGroupProps) => {
    const { children } = props;

    return <StyledButtonGroup>{children}</StyledButtonGroup>;
};

interface SwButtonGroupProps {
    children?: any;
}

export default SwButtonGroup;
