import { range } from '../../../lib/helpers/array';

export const generatePagination = (siblingCount: number, totalPageCount: number, selectedPage: number): number[] => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2 * three dots
    const totalPageNumbers = siblingCount + 5;

    // If the number of pages is less than the page numbers we want to show, lets return full range
    if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount + 1);
    }

    // Calculate left and right sibling value and make sure they are within range 1 and totalPageCount
    const leftSiblingValue = Math.max(selectedPage - siblingCount, 1);
    const rightSiblingValue = Math.min(selectedPage + siblingCount, totalPageCount);

    // Let's check if we need left or/and right dots
    const addLeftDots = leftSiblingValue > 2;
    const addRightDots = rightSiblingValue < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // Only right dots needed (dots are represented using -1 value)
    if (!addLeftDots && addRightDots) {
        const leftItemCount = 3 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount + 1);
        return [...leftRange, -1, totalPageCount];
    }

    // Only left dots needed
    if (addLeftDots && !addRightDots) {
        const rightItemCount = 3 + 2 * siblingCount;
        const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount + 1);
        return [firstPageIndex, -1, ...rightRange];
    }

    // Both needed
    if (addLeftDots && addRightDots) {
        const middleRange = range(leftSiblingValue, rightSiblingValue + 1);
        return [firstPageIndex, -1, ...middleRange, -1, lastPageIndex];
    }
};
