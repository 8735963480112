import React, { useMemo } from 'react';
import { Stack, StackProps } from '@mui/material';
import { grey3 } from '@lib/components/bedrock/SwColors';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

export type DateCardProps = StackProps & {
    startDate: string;
};

export const DateCard: React.FC<DateCardProps> = (props) => {
    const { startDate, ...rest } = props;

    const date = useMemo(() => new Date(startDate), [startDate]);
    const month = useMemo(() => {
        return date.toLocaleDateString('en-EN', { month: 'short' });
    }, [date]);
    const day = date.toLocaleDateString('en-EN', { day: 'numeric' });

    return (
        <Stack alignItems={'center'} {...rest}>
            <SwTypography bold={true} color={grey3} uppercase={true} variant={'caption'}>
                {month}
            </SwTypography>
            <SwTypography fontSize={18} uppercase={true} variant={'bodyStrong'}>
                {day}
            </SwTypography>
        </Stack>
    );
};
