import React from 'react';
import { Box, Grid } from '@mui/material';
import { grey3 } from './bedrock/SwColors';
import SwSpacer from './bedrock/layout/SwSpacer';
import SwTypography from './bedrock/typography/SwTypography';

export type SwEmptySectionProps = {};

const SwEmptySection: React.FC<SwEmptySectionProps> = () => (
    <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Grid item={true} md={6} sm={12}>
            <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
                <SwTypography variant={'h4'}>You’ve gone too deep</SwTypography>
                <SwSpacer direction={'vertical'} spacing={1} />
                <SwTypography color={grey3}>There are no results with your filters or searches.</SwTypography>
                <SwSpacer direction={'vertical'} spacing={2} />
            </Box>
        </Grid>
    </Box>
);

export default SwEmptySection;
