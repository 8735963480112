import React, { useMemo } from 'react';
import { SwButton, SwButtonKind } from '@lib/components/bedrock/button/SwButton';
import SwButtonGroup from '@lib/components/bedrock/button/SwButtonGroup';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { generatePagination } from './helper';

export interface SwPaginationProps {
    pageCount: number;
    currentPage: number;
    basePath: string;
    siblingCount?: number;
    target?: `#${string}`;
}

const SwPagination: React.FunctionComponent<SwPaginationProps> = ({
    pageCount,
    basePath,
    currentPage,
    target,
    siblingCount = 1
}: SwPaginationProps) => {
    const paginationRange = useMemo(() => {
        return generatePagination(siblingCount, pageCount, currentPage);
    }, [pageCount, currentPage, siblingCount]);

    if (pageCount === 1) {
        return null;
    }

    return (
        <SwButtonGroup>
            {paginationRange.map((page, index) => {
                if (page === -1) {
                    return (
                        <SwButton
                            ariaLabel={'More'}
                            disabled={true}
                            key={index}
                            kind={SwButtonKind.Secondary}
                            size={'small'}
                        >
                            ...
                        </SwButton>
                    );
                } else {
                    return (
                        <SwInternalLink
                            active={currentPage === page}
                            aria-label={`Got to page ${page}`}
                            key={index}
                            size={'small'}
                            to={page === 1 ? `${basePath}${target ?? ''}` : `${basePath}/${page}${target ?? ''}`}
                            variant={'secondary'}
                            withI18n={false}
                        >
                            {page}
                        </SwInternalLink>
                    );
                }
            })}
        </SwButtonGroup>
    );
};

export default SwPagination;
